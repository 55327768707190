
export const tasks = [
["Each member of your team saying their name","V","500"],
["One member of your team saying everyone else's name from memory","V","1000"],
["How many different Global Rounds did your team members attend?","V","500 per"],
["Two team members with the most similar personalities","P","500"],
["Two team members with the most different personalities","P","500"],
["How many alpacas does your team have?","P","50 per"],
["Write a song introducing each person on your team","V","1000"],
["A live performance of that song","V","2000"],
["Entire team jumping at the same time","P","1000"],
["Give a motivational speech to a nervous team member","V","750"],
["Give a motivational speech to a nervous Scavenge leader","V","1250"],
["A human pyramid","P","1000"],
["A human sphere","P","1500"],
["A human Möbius strip","P","2000"],
["Who on your team…","",""],
["…is the most awkward?","F","250"],
["…is the least awkward?","F","250"],
["…is the sleepiest?","F","250"],
["…is the awakest?","F","250"],
["…is the best dancer?","F","250"],
["…is the worst dancer?","F","250"],
["…is the coldest?","F","250"],
["…has been to the most WSC rounds?","F","250"],
["…is the loudest?","F","250"],
["…looks the most like an alpaca?","F","250"],
["…has the best haircut?","F","250"],
["…is the most fashionable?","F","250"],
["…is the least fashionable?","F","250"],
["…traveled the longest to get here?","F","250"],
["…is studying during the Scavenge?","F","250"]
]


export const tasks2 = [
    ["Entire team shouting your team name","V","500"],
    ["Create and perform a team chant","V","1500"],
    ["What is the mascot of your university?","","F","250"],
    ["Draw the mascot","P","750"],
    ["Draw an alpaca dressed up as the mascot","P","1250"],
    ["What is your university's rival university?","","F","250"],
    ["Rap about how your university is better than them","V","1500"],
    ["Make a pun based on your university's name","V","750"],
    ["Apply to your university","P","1000"],
    ["Do an impression of a student from your university","V","1500"]
]

export const tasks3 = [
    ["Team member listing things that could go wrong","V","1000"],
    ["Something going wrong","V","750"],
    ["Something going right","V","750"],
    ["Team member listing ways to be awkward","V","1000"],
    ["Team member doing push-ups","V","50 per"],
    ["Team member doing push-downs","V","50 per"],
    ["Team member doing a cartwheel","V","750"],
    ["Teach a WSC staff member to cartwheel","V","1500"],
    ["Team member making artwork using currencies from different countries","P","200 per country"],
    ["Team member wearing Yale colors","P","500"],
    ["Team member wearing Harvard colors","P","750"],
    ["Team member doing an interpretive dance to one of this year's songs","V","1750"],
    ["Team member predicting where next year's Globals will be","V","500"],
    ["A correct prediction","V","2000"],
    ["Team member rehearsing their ToC Scholar's show performance","V","1000"],
    ["Team member doing an impression of a WSC staff member","V","1000"],
    ["An impression of a WSC staff member next to that staff member","V","2000"],
    ["Create your own Scavenge task! Points will be awarded for impressiveness.","P/V","?"]
]

export const tasks4 = [
    ["Ask WSC staff member about things that could go wrong","V","1000"],
    ["Who is the most optimistic staff member?","","F","750"],
    ["Daniel giving your team a book recommendation","V","1000"],
    ["Daniel observing a Breakfast ritual","P","1500"],
    ["Discover Daniel's secret name","V","2000"],
    ["Jason listing debate venues","V","250 per"],
    ["Jason giving a team member a medical diagnosis","V","1500"],
    ["Give Robert a math problem to solve","V","1250"],
    ["President Robert","P","1250"],
    ["Olivia cancelling the Tournament of Champions","V","1000"],
    ["A picture of Olivia's puppy","P","1500"],
    ["Dylan explaining the features of the site","V","1000"],
    ["Dylan adding a reference to your Scavenge team to the site","P","2000"],
    ["Dylan with an Atlas","P","1000"],
    ["Ask Lexi about her weirdest schooling experience","V","1250"],
    ["Have a conversation with Lexi in non-English","V","1500"],
    ["Give Chauncey a music recommenxdation","V","1000"],
    ["Speak Dolphin to Chauncey","V","1500"],
    ["Tim teaching your team Australian","V","1500"],
    ["Ask Tim about his alternate Challenge scoring system","V","1000"],
    ["Logan complaining about the cold","V","1000"],
    ["Logan dispensing advice","V","1250"],
    ["Logan trying to break your poker face","V","1500"],
    ["Catch Stephanie or Steve taking a photo of another Scavenge team","P","1500"],
    ["Another staff member with either Stephanie's or Steve's camera","P","2000"],
    ["Terran taking an exam","P","1000"],
    ["Terran on Google Streetview","P","2000"],
    ["Ask Terran: what features does he want in an apartment?","V","1000"],
    ["KK dancing for longer than 10 seconds","V","5000"],
    ["KK giving your team DJing lessons","V","2000"],
    ["Vishal acting fatherly","V","1750"],
    ["Give Vishal a recommendation on where you want a Global Round","V","1000"],
    ["Thishin giving your team fire safety tips","V","1500"],
    ["Ask Thishin: whats the best place to eat in Cape Town?","V","1000"],
    ["Send a hello message to Maris and Min in China","V","1000"],
    ["Patrick reenacting his role from the Cape Town Global Round open","V","1500"],
    ["Patrick driving a U-Haul","P","1500"],
    ["Rosie doing a Pikapoo impression","V","2000"],
    ["Tom explaining normalization to your team","V","1000"],
    ["Tom turning your team normal","P","1500"],
    ["Ask Tyus: how vaccinated are the alpacas?","V","1000"],
    ["Tyus saying “Go Harvard!”","V","2000"],
    ["Aled and Rachel!","P","1500"],
    ["Aled or Rachel running a marathon","P","2000"],
    ["Aled or Rachel running away from Taiwan","P","2000"],
    ["Milo is a cat","P","1500"],
    ["Count to three in French to Milo as many times as you can","V","100 per"],
    ["Joseph whipping his hair back and forth","V","1500"],
    ["Joseph showing you a photo of his baby","P","1000"],
    ["Shave Joseph's head","V","4000"],
    ["Julia explaining what your favorite WSC event means about you","V","1250"],
    ["Be on Julia's Instagram story","P","2000"],
    ["Sol riding a horse","P","2000"],
    ["Sol's Waluigi impression","V","1500"],
    ["Elaine ranking Christopher Nolan films","V","1500"],
    ["Recite digits of pi from memory to Elaine","V","31.4 per digit"],
    ["Sally recounting her Duolingo experience","V","1500"],
    ["Show Sally: who has the longest Duolingo streak?","P","10 per day"],
    ["BRB filming video footage","V","1000"],
    ["Someone from BRB giving you an interview","V","1500"],
    ["Convince BRB to include the word “tomato” somehow in the ToC video","V","2000"],
    ["Ask Tio Pato for Rosie's real name","V","1500"],
    ["Kylie!","P","2500"]
]

export const tasks5 = [
    ["Selfie with an alpaca","P","1000"],
    ["Alpaca refuses a selfie","P","1500"],
    ["Pwaa at Painted Warrior","V","1000"],
    ["A staff member pwaaing at Painted Warrior","V","2000"],
    ["Painted Warrior pwaaing at anyone","V","2000"],
    ["Painted Warrior spitting at anyone","V","-2000"],
    ["Painted Warrior having a snack","P","1000"],
    ["Introduce Trevor to a Jerry","P","1500"],
    ["Discuss: is Trevor Painted Warrior's sidekick?","V","1000"],
    ["Create a theme song for the alpacas","V","2000"],
    ["Denise taking care of the alpacas","P","1500"],
    ["Learn how to take care of alpacas from Denise","V","2500"],
    ["Another team taking photos of an alpaca","P","1000"],
    ["Another team taking photos of a team taking photos of an alpaca","P","2000"],
    ["Challenge an alpaca to an Alpaca Balance Challenge","V","1500"],
    ["Read a love poem to an alpaca","V","1000"],
    ["Have a staring contest with an alpaca","V","1500"],
    ["Make a rainbow out of different colored leaves","P","1250"],
    ["Make it rain with leaves","V","1500"],
    ["Have a fall photoshoot","P","1000"],
    ["Fall over during the photoshoot","V","1500"],
    ["Propose to a tree","V","1000"],
    ["Hug a tree","P","1250"],
    ["Give a motivational speech to a tree","V","1500"],
    ["Give a motivational speech to a fire hydrant","V","1750"],
    ["Conga line down one of the pathways on the Green","V","1500"],
    ["Another team joins the conga line!","V","2500"]
]

export const tasks6 = [
    ["Make a checklist at Verizon","P","500"],
    ["Gaze toward the horizon at Verizon","P","750"],
    ["Holler to a teammate at Halal Guys","V","750"],
    ["Boost up a teammate at Boost Mobile","V","1000"],
    ["A team member studying the Booster Pack","P","500"],
    ["Pronounce “Chipotle” incorrectly at Chipotle","V","1000"],
    ["Make a human train outside Subway","P","1000"],
    ["Tom at Subway","P","2000"],
    ["Another country's metro card at Subway","P","750"],
    ["A vegetarian at Garden Catering","P","750"],
    ["Guard a teammate","V","1000"],
    ["Eat something inedible at Edible Arrangements","V","750"],
    ["Eat a teammate","V","1250"],
    ["Count in Roman numerals at ViVi","V","1000"],
    ["What's the best bubble tea order?","V","500"],
    ["Declare your Scavenge team a monarchy at China King","V","750"],
    ["Reenact a scene from Star Wars at the Taco Bell Cantina","V","1500"],
    ["Ring a bell","V","500"],
    ["Sink to your knees at Zinc","P","500"],
    ["How many elements can you name from memory?","V","25 per"],
    ["Make a contract with your team at Modern Trousseau","V","1500"],
    ["Sneak up on a teammate at Sneaker Junkies","V","1000"],
    ["Dylan at Sneaker Junkies","P","2000"],
    ["Make a secret handshake at Shake Shack","V","1500"],
    ["Another team who gave up on the Scavenge to chill at Shake Shack","P","1000"],
    ["Do something extraordinary at Ordinary","V","1000"],
    ["Do something extra ordinary","V","1000"],
    ["Be a WSC Tournament Director at TD Bank","V","1250"],
    ["Corner a teammate at Claire's Corner Copia","P","750"],
    ["Cope","V","1000"],
    ["Be a traitor to your team at Basta Trattoria","V","1250"],
    ["Create an idiom about alpacas at Idiom","P","750"],
    ["Use that idiom in a conversation","V","1500"],
    ["Moo at Arethusa","V","500"],
    ["Pwaa at Arethusa","V","750"],
    ["Wrap a teammate at Sushi on Chapel","P","1000"],
    ["Your team uniting at Union League Café","P","750"],
    ["Your team un-uniting","P","750"],
    ["Perform magic at Merwin's Art Shop","V","1500"],
    ["Reenact The Little Mermaid at Merwin's Art Shop","V","1500"],
    ["Your team listing your home cities at Ten Thousand Villages","V","1000"],
    ["Make a bread pun at Panera Bread","V","1000"]
]

export const tasks7 = [
    ["Act caffeinated at Starbucks Coffee","V","1250"],
    ["A seasonal Starbucks drink","P","1000"],
    ["Pluck out a hair at Yale Haircutting","P","750"],
    ["Who on your team has the best haircut?","P","500"],
    ["Restyle a teammate's hair","V","1750"],
    ["A frozen teammate at Froyo World","P","500"],
    ["Reenact a scene from Frozen","V","1500"],
    ["Frodo Baggins at Froyo World","P","1000"],
    ["Do a British accent at the Yale Center for British Art","V","1250"],
    ["Do a Yale accent","V","1000"],
    ["Be healthy at Salad Shop","V","750"],
    ["Be unhealthy","V","750"],
    ["Make a sale to your teammate at Salad Shop","V","1250"],
    ["Haze a teammate at a frat house","V","1500"],
    ["Recite the Greek alphabet at a frat house","V","1000"],
    ["Do the mambo at Rubamba","V","1250"],
    ["Do the rumba at Soul de Cuba","V","1250"],
    ["Minecraft in real life at Milkcraft","V","1750"]
]

export const tasks8 = [
    ["Love your team at Te Amo","V","750"],
    ["Fall in love with an alpaca","V","1000"],
    ["Teach an alpaca Spanish","V","1250"],
    ["Tie a teammate's shoes at Koon Thai","P","1000"],
    ["Make a human knot","P","1750"],
    ["Mark a teammate at Marketa","P","750"],
    ["Start an alpaca black market","V","1500"],
    ["Spread fake news about WSC at Lalibela","V","750"],
    ["List prime numbers at Prime 16","V","11 per"],
    ["What are the prime factors of your three-digit team number?","P","1000"],
    ["Be omnipotent at the Omni","V","1250"],
    ["Be omnipresent at the Omni","P","1250"],
    ["Give a teammate a present at the Omni","P","1500"],
    ["Give a teammate a future at the Omni","V","1500"],
    ["A Jerry at Ben & Jerry's","P","750"],
    ["Brain freeze!","V","1500"],
    ["Pit two teammates against each other at Pitaziki","V","1000"],
    ["Be zen at Temple Grill","P","1000"],
    ["Interrogate a teammate","V","1250"],
    ["Who on your team would be good cop and who would be bad cop?","P","750"],
    ["Pose as the Statue of Liberty at Liberty","P","1000"],
    ["Run the 10-meter dash at Olympia","V","1250"],
    ["Make owl noises at Olympia","V","750"],
    ["Sing an Ed Sheeran song at Barcelona","V","1250"],
    ["Be slippery at Olives and Oil","V","1000"]
]

export const tasks9 = [
    ["Love your team at Te Amo","V","750"],
    ["Fall in love with an alpaca","V","1000"],
    ["Teach an alpaca Spanish","V","1250"],
    ["Tie a teammate's shoes at Koon Thai","P","1000"],
    ["Make a human knot","P","1750"],
    ["Mark a teammate at Marketa","P","750"],
    ["Start an alpaca black market","V","1500"],
    ["Spread fake news about WSC at Lalibela","V","750"],
    ["List prime numbers at Prime 16","V","11 per"],
    ["What are the prime factors of your three-digit team number?","P","1000"],
    ["Be omnipotent at the Omni","V","1250"],
    ["Be omnipresent at the Omni","P","1250"],
    ["Give a teammate a present at the Omni","P","1500"],
    ["Give a teammate a future at the Omni","V","1500"],
    ["A Jerry at Ben & Jerry's","P","750"],
    ["Brain freeze!","V","1500"],
    ["Pit two teammates against each other at Pitaziki","V","1000"],
    ["Be zen at Temple Grill","P","1000"],
    ["Interrogate a teammate","V","1250"],
    ["Who on your team would be good cop and who would be bad cop?","P","750"],
    ["Pose as the Statue of Liberty at Liberty","P","1000"],
    ["Run the 10-meter dash at Olympia","V","1250"],
    ["Make owl noises at Olympia","V","750"],
    ["Sing an Ed Sheeran song at Barcelona","V","1250"],
    ["Be slippery at Olives and Oil","V","1000"]
]

export const tasks10 = [
    ["Be slippery at Olives and Oil","V","1000"],
    ["Create a mathematical expression at Expressions","P","750"],
    ["Make a silly expression at Expressions","P","750"],
    ["Solve the silly expression","V","1500"],
    ["Train a teammate at Metro","V","1000"],
    ["T-pose at T-Mobile","P","1000"],
    ["Terran drinking tea at T-Mobile","P","1500"],
    ["Have a highbrow discussion at iBrow Studios","V","750"],
    ["Have a mediumbrow discussion","V","1000"],
    ["Robert Brow","P","1500"],
    ["Five teammates at Five Guys","V","500"],
    ["Five alpacas at Five Guys","V","750"],
    ["Entire team saying “Ahhhh” at Dr. Dental","P","1250"],
    ["Explain the rules of cricket at Cricket","V","1000"],
    ["Make cricket chirping noises","V","1500"],
    ["Provide therapy for a teammate at Caring People","V","1500"],
    ["Carry a teammate","P","1000"],
    ["Dunk on a teammate at Dunkin' Donuts","V","750"],
    ["Make a teammate super mad at Cajun Boiled Seafood","V","750"],
    ["Cage a teammate","P","1000"],
    ["Arm-wrestle at the Armed Forces Career Center","V","1250"],
    ["Gatekeep at Gateway","V","1000"],
    ["Gaslight at Gateway","V","1000"],
    ["Girlboss at Gateway","V","1000"],
    ["Experiment on a teammate at the Bioscience Center","V","1500"],
    ["Perform a ritual at Rite Aid","V","1500"]
]

export const tasks11 = [
    ["Pose like a Renaissance painting","P","1250"],
    ["Recreate a new version of your favorite song","V","1500"],
    ["Perform an a cappella version of new version","V","2500"],
    ["Reenact a scene from a Shakespeare play","V","1500"],
    ["Modernize a scene from a Shakespeare play","V","2000"],
    ["Coup your Scavenge Leader","V","1000"],
    ["Reinstall your Scavenge Leader","V","2000"],
    ["Find an object that gives leadership of your Scavenge team to whoever holds it","P","1500"],
    ["What would the headquarters of your team look like? Describe the building.","V","1000"],
    ["Reenact the Year Without A Summer","V","1250"],
    ["Reenact the Messinian Crisis","V","1250"],
    ["A scene from a floating city","P","2000"],
    ["Rebrand your Scavenge team by giving it a new…","",""],
    ["…logo","P","1000"],
    ["…slogan","P","1000"],
    ["…flag","P","1000"],
    ["Commit a logical fallacy","V","1250"],
    ["Plagiarize another team's rebranding","P","1000"],
    ["Apologize to your Scavenge Leader","V","1500"],
]

export const tasks12 = [
    ["Decorate the Omni with alpacas","P","1500"],
    ["Make a rainbow of Jerries","P","2000"],
    ["Photo with the team that arrived before you","P","1000"],
    ["Photo with as many teams as possible","P","500 per"],
    ["Show love for your Scavenge Leader","V","2000"],
    ["Introduce one of your coaches to a new friend","V","1500"],
    ["Introduce one of your coaches to a new enemy","V","1500"],
    ["One final group hug","P","5000"]
]

