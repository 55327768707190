import React from 'react'
import styled from 'styled-components'


const General = () => {
  return (
    <Container>
        <HeaderText>
            GENERAL INFORMATION
        </HeaderText>
        <BodyText>
        We’re so happy to have you here! As a rule of thumb, please have your nametag with you at all times. Access to events will be available only to registered participants (scholars, DJs, and OGs). The Closing Ceremony, however, will be also be open to those who purchased tickets in advance.       
        </BodyText>

        <HeaderText>
            VENUES
        </HeaderText>
        <BodyText>
            <SubtitlePaddingLess>Shubert Theater</SubtitlePaddingLess>Opening Ceremony, Keynote Address, and Scavenge Briefing<br/>
            <Subtitle>Yale University Buildings (SSS & LC), Cooperative Arts and Humanities High School (Co-op), &
Hillhouse High School</Subtitle>Team Debate, Collaborative, Writing, and Scholar’s Challenge<br/>
            <Subtitle>Woolsey Hall</Subtitle>Scholar’s Bowl, Scholar’s Show, Debate Showcase, and Closing Ceremony. Note that food, beverages, and bags (including purses, backpacks, and suitcases) are not permitted at Woolsey, and that, all participants must wear masks in the building<br/>
            <Subtitle>Omni Hotel</Subtitle>Check-in, most meals (more details below), and the Junior’s Scholar’s Ball<br/>
            <Subtitle>College Street Music Hall</Subtitle>Senior Scholar’s Ball<br/>
        </BodyText>

        <HeaderText>
            TRANSPORTATION & DEBATE DAY LOGISTICS
        </HeaderText>
        <BodyText>
        Be sure to consult your nametag to check the location of your debates. While most locations will be within walking distance of the Omni and other hotles in New Haven, some of them are located a little further away.
        
        <br/><br/>To help clarify:<br/><br/>
        <b>If you’re staying in the New Haven area</b>, bussing will be provided to Hillhouse High School on Debate Day. For Juniors, that shuttle will depart from Linsly-Chittenden Hall (LC) at 8:30 on November 12; for Seniors, it will depart from Woolsey Hall at 8:30 on November 13.<br/><br/>

        <b>For those of you who have reserved transportation from Milford and Shelton</b>, please note that, on debate day, the busses will go to Hillhouse first, and then to Linsly-Chittenden Hall (LC) on Junior Debate Day and Sheffield-Sterling-Strathcona Hall (SSS) on Senior Debate Day. From these locations, please check in or make your way to Co-op. <b>In the evening</b> on Debate Days, buses will depart for Milford and Shelton from Hillhouse High School and Linsly-Chittenden Hall for Juniors and Hillhouse High School and Woolsey Hall for Seniors.<br/><br/>
        
        <b>Lastly, if you’re making your way to the locations on your own</b>, the full addresses are as follows:
        
        <br/><br/> - Linsly-Chittenden Hall (LC): 63 High St, New Haven, CT 06511
        <br/><br/> - Sheffield-Sterling-Strathcona Hall (SSS): 1 Prospect St, New Haven, CT 06511
        <br/><br/> - Cooperative Arts and Humanities High School (Co-op): 177 College St, New Haven, CT 06510
        <br/><br/> - Hillhouse High School: 480 Sherman Parkway, New Haven, CT 06511
        </BodyText>
        
        <HeaderText>
            DINING LOCATIONS
        </HeaderText>
        <BodyText>
        We’re using a variety of venues for lunches and dinner at and around Yale; those locations are summarized on your nametags. For some more detail, including dates of meals:
        <br/><br/> - <b>November 11</b>: Lunch and Scholar’s Social will both be at the Omni Hotel.
        <br/><br/> - <b>November 12</b>: Juniors will be served lunch at their debate school. For Seniors, lunch will be at the Omni, and the Scholar’s Social will be held at both the Omni and 80 Proof American Kitchen (196 Crown St) near the Omni.
        <br/><br/> - <b>November 13</b>: Seniors will be served lunch at their debate school.
        <br/><br/> - <b>November 14</b>: Lunch for everyone will be served at the Omni. After the show, Seniors will depart at 6:00
pm to Yale Dining or the Omni, followed by Juniors at 6:45 pm.
        <br/><br/> - <b>November 16</b>: Lunch for everyone will be served at the Omni. The Smurf Barbecue does not involve any food. We will eventually clarify what it means. {"<3"}
        </BodyText>

        <HeaderText>
            DEBATE JUDGES (DJs) & SCAVENGE LEADERS
        </HeaderText>
        <BodyText>
            All DJs and Scavenge Leaders have received subsidized registration fees as our great thanks for judging six debates or leading a team for the Scholar’s Scavenge—or both! Temporary nametags are valid only for the opening day. Official nametags for the remainder of the event will be issued after judging all assigned debates or finishing the scavenge.<br/><br/>
            
            Adjudicator Training will take place on opening day (November 11 or 12), at 11:00 am in the College Room at the Omni (not Wooster). Scavenge Leader Training will take place immediately after, at 12:30 pm, also in the College Room.<br/><br/>
            
            Enjoy Yale! And, as always, please let us know if there’s anything we can do to be of help.<br/><br/>
        </BodyText>


    </Container>
  )
}

export default General

const Subtitle = styled.div`
    margin: 10px 0px 2px 0px;
    font-weight: 600;
    color: black;
`

const SubtitlePaddingLess = styled.div`
    margin: 0px 0px 2px 0px;
    font-weight: 600;
    color: black;
`

const Container = styled.div`
    padding: 10px 30px 30px 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
`

const HeaderText = styled.text`
    color: rgb(30,31, 65);;
    font-weight: 600;
    text-align: left;
    padding-bottom: 5px;
    padding-top: 20px;
    font-size: 1.5em;
`

const BodyText = styled.text`
    color: black;
    text-align: left;
    font-weight: 400;
`