import React, {useState} from 'react'
import jr1 from '../jr1.png'
import jr2 from '../jr2.png'
import sr1 from '../sr1.png'
import sr2 from '../sr2.png'
import bus from '../bus.png'
import styled from 'styled-components'
import MultiSwitch from 'react-multi-switch-toggle'


const Schedule = () => {

  const [sched, setSched] = useState(0);

  const onToggle = (selectedItem) => {
    setSched(selectedItem)
}

  
  return (
    <Container>
    <SwitchContainer>
            <MultiSwitch
                texts={[
                'Junior',
                'Senior',
                'Shuttle',

                ]}
                selectedSwitch={0}
                bgColor={'#ffffff'}
                onToggleCallback={onToggle}
                fontColor={'rgb(30,31, 65)'}
                selectedFontColor={'#FFFFFF'}
                eachSwitchWidth={100}
                height={'25px'}
                fontSize={'16px'}
                selectedSwitchColor={"rgb(30,31, 65)"}
                borderWidth={"1"}
            >
            </MultiSwitch>
        </SwitchContainer>

  {sched === 1 &&
      <JrContainer>
        <Title>Senior Division Schedule</Title>
      <img src={sr1} className="schedule" alt="Logo" />
      <img src={sr2} className="schedule" alt="Logo" />
      </JrContainer>
    }

    {sched === 0 &&
      <JrContainer>
      <Title>Junior Division Schedule</Title>
      <img src={jr1} className="schedule" alt="Logo" />
      <img src={jr2} className="schedule" alt="Logo" />
      </JrContainer>
    }

    {sched === 2 &&
      <JrContainer>
      <Title>Shuttle Bus Schedule</Title>
      <img src={bus} className="schedule" alt="Logo" />
      </JrContainer>
    }

    </Container>
  )
}

export default Schedule

const JrContainer = styled.div`
    padding-top: 20px;
    padding-bottom: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
    top: 0;
    background-color: white;
    flex-direction: column;
`
const SwitchContainer = styled.div`
    align-items: center;
    justify-content: center;
    padding-top: 20px;
`

const Container = styled.div`
  background-color: white;
`

const Title = styled.div`
  color: rgb(30,31, 65);
  font-weight: 600;
  font-size: 1.5em;
  margin-bottom: 10px;
`