import React, { useState } from 'react'
import Iframe from './iframe.js';
import styled from 'styled-components'
import map from '../map.png';
import MultiSwitch from 'react-multi-switch-toggle'
import Collapsible from 'react-collapsible';
import {MdOutlineKeyboardArrowDown} from 'react-icons/md'


const DebateDay = () => {

    const [selectedMap, setSelectedMap] = useState(0);

    const onToggle = (selectedItem) => {
        setSelectedMap(selectedItem)
    }

  return (
    <>
    <Container>
        <SwitchContainer>
            <MultiSwitch
                texts={[
                'Map',
                'Addresses',
                ]}
                selectedSwitch={0}
                bgColor={'#ffffff'}
                onToggleCallback={onToggle}
                fontColor={'rgb(30,31, 65)'}
                selectedFontColor={'#FFFFFF'}
                eachSwitchWidth={150}
                height={'25px'}
                fontSize={'16px'}
                selectedSwitchColor={"rgb(30,31, 65)"}
                borderWidth={"1"}
            >
            </MultiSwitch>
        </SwitchContainer>
       
        {selectedMap === 0 && <><HeaderText>
        MAP
        </HeaderText>
        <MapContainer>
            <img src={map} className="tocmap" alt="Logo" />
        </MapContainer></>}


        {selectedMap === 1 && <>

            <HeaderText>
            LOCATIONS
            </HeaderText>
            <Collapsible trigger={["Debate, Challenge & Writing", <MdOutlineKeyboardArrowDown/>]}>
                <InnerContainer>
                    
                    <Collapsible trigger={["Co-op High School (Co-op)", <MdOutlineKeyboardArrowDown/>]}>
                        <LocationContainer>
                            <Iframe source={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.154988987295!2d-72.929631!3d41.3054918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d84ceb772157%3A0xbf80cf9f02e6c6ee!2sCooperative%20Arts%20and%20Humanities%20High%20School!5e0!3m2!1sen!2sus!4v1667894872274!5m2!1sen!2sus"} />
                        </LocationContainer>
                    </Collapsible>

                    <Collapsible trigger={["Davies Auditorium (Davies)", <MdOutlineKeyboardArrowDown/>]}>
                        <LocationContainer>
                            <Iframe source={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.8289349564193!2d-72.92497039999999!3d41.312584900000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d9b6595d15fd%3A0xef0cf7cac5794881!2sDavies%20Auditorium!5e0!3m2!1sen!2sus!4v1667894966983!5m2!1sen!2sus"} />
                        </LocationContainer>
                    </Collapsible>

                    <Collapsible trigger={["SSS Hall (SSS)", <MdOutlineKeyboardArrowDown/>]}>
                        <LocationContainer>
                            <Iframe source={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.86126154745!2d-72.9251955!3d41.3118817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d9b663b2df59%3A0x57ccaa8f74703ebb!2sSheffield-Sterling-Strathcona%20Hall!5e0!3m2!1sen!2sus!4v1667895058090!5m2!1sen!2sus"} />
                        </LocationContainer>
                    </Collapsible>

                    <Collapsible trigger={["Linsly-Chittenden Hall (LC)", <MdOutlineKeyboardArrowDown/>]}>
                        <LocationContainer>
                            <Iframe source={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.0156846657696!2d-72.9294701!3d41.3085224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d9b36dc3a513%3A0x5eae6f84c84d5e92!2sLinsly-Chittenden%20Hall%2C%2063%20High%20St%2C%20New%20Haven%2C%20CT%2006511!5e0!3m2!1sen!2sus!4v1667895184140!5m2!1sen!2sus"} />
                        </LocationContainer>
                    </Collapsible>
                
                    <Collapsible trigger={["Watson Center(Watson)", <MdOutlineKeyboardArrowDown/>]}>
                        <LocationContainer>
                            <Iframe source={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.680990529587!2d-72.92595864889701!3d41.315803008290786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d9b81ed5bd2d%3A0x4827cd2fd9fc18ae!2sWatson%20Center!5e0!3m2!1sen!2sus!4v1667895240520!5m2!1sen!2sus"} />
                        </LocationContainer>
                    </Collapsible>

                    <Collapsible trigger={["William L. Harkness (WLH)", <MdOutlineKeyboardArrowDown/>]}>
                        <LocationContainer>
                            <Iframe source={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.0156846657696!2d-72.9294701!3d41.3085224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d9b43d6fa73f%3A0x2ebcf5ecba4e0799!2sWilliam%20L.%20Harkness%20Hall!5e0!3m2!1sen!2sus!4v1667895209793!5m2!1sen!2sus"} />
                        </LocationContainer>
                    </Collapsible>

                    <Collapsible trigger={["Hillhouse HS (Hillhouse)", <MdOutlineKeyboardArrowDown/>]}>
                        <LocationContainer>
                            <Iframe source={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2996.400040844329!2d-72.9398471!3d41.3219137!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d9a476fe19f3%3A0xaf09039d076a0e5f!2sHillhouse%20High%20School!5e0!3m2!1sen!2sus!4v1667894972885!5m2!1sen!2sus"} />
                        </LocationContainer>
                    </Collapsible>
                </InnerContainer>
            </Collapsible>

            <Collapsible trigger={["Omni Hotel", <MdOutlineKeyboardArrowDown/>]}>
                <InnerContainer>
                    <LocationContainer>
                        <Iframe source={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.1458232304!2d-72.9294957489598!3d41.30569120891693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d84b45005aef%3A0x8cfc0fe0a0ceb073!2sOmni%20New%20Haven%20Hotel%20at%20Yale!5e0!3m2!1sen!2sus!4v1668038708415!5m2!1sen!2sus"} />
                    </LocationContainer>
                </InnerContainer>
            </Collapsible>

            <Collapsible trigger={["Shubert Theater", <MdOutlineKeyboardArrowDown/>]}>
                <InnerContainer>
                    <LocationContainer>
                        <Iframe source={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.1139234967864!2d-72.93107284895976!3d41.30638520887385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d84cb2252cad%3A0xb8a785eec0392d79!2sShubert%20Theater!5e0!3m2!1sen!2sus!4v1668038811504!5m2!1sen!2sus"} />
                    </LocationContainer>
                </InnerContainer>
            </Collapsible>

            <Collapsible trigger={["Woolsey Hall", <MdOutlineKeyboardArrowDown/>]}>
                <InnerContainer>
                    <LocationContainer>
                        <Iframe source={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.885629919378!2d-72.92825464895955!3d41.31135160856651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d9b69d1ee937%3A0x79d30210d26343f5!2sWoolsey%20Hall!5e0!3m2!1sen!2sus!4v1668038845286!5m2!1sen!2sus"} />
                    </LocationContainer>
                </InnerContainer>
            </Collapsible>

            <Collapsible trigger={["College Street Music Hall", <MdOutlineKeyboardArrowDown/>]}>
                <InnerContainer>
                    <LocationContainer>
                        <Iframe source={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.885629919378!2d-72.92825464895955!3d41.31135160856651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d84cb45568b9%3A0x93961f68d1d02568!2sCollege%20Street%20Music%20Hall!5e0!3m2!1sen!2sus!4v1668038880228!5m2!1sen!2sus"} />
                    </LocationContainer>
                </InnerContainer>
            </Collapsible>

            <Collapsible trigger={["Yale Commons", <MdOutlineKeyboardArrowDown/>]}>
                <InnerContainer>
                    <LocationContainer>
                        <Iframe source={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.8683175960027!2d-72.92844754895961!3d41.311728208543265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d9b687d77d53%3A0xe4887c80c2d9cf3!2sYale%20University%20Commons%20Dining%20Hall!5e0!3m2!1sen!2sus!4v1668038914281!5m2!1sen!2sus"} />
                    </LocationContainer>
                </InnerContainer>
            </Collapsible>

        </>}

    </Container>
    </>
  )
}

export default DebateDay

const Container = styled.div`
    padding: 20px 30px 30px 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
`

const HeaderText = styled.text`
    color: rgb(30,31, 65);
    font-weight: 600;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 20px;
    font-size: 1.25em;
`

const MapContainer = styled.div`
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
    -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
`

const LocationContainer = styled.div`
    width: 99.5%;
`

const SwitchContainer = styled.div`
    align-items: center;
    justify-content: center;
`
const LocationTitle = styled.div`
    padding: 10px;
    padding-left: 10px;
    font-weight: 600;
`

const InnerContainer = styled.div`
    padding: 10px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`