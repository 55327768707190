import React, { useState } from 'react'
import styled from 'styled-components'
import logo from '../logo.png'
import Pill from './Pill'



const Header = ({selected, setSelected}) => {

    const [tabName, setTabName] = useState("Information")

  return (
    <>
    <Top/>
    <Container>
        <img src={logo} className="logo" alt="Logo" />
        <Left>
            <TopText>
                Tournament of Champions 2022
            </TopText>
            <BottomText>
                {tabName}
            </BottomText>
        </Left>
    </Container>
    <SwitchContainer>
        <Pill idx={0} name={"Information"} selected={selected} setSelected={setSelected} setTabName={setTabName}/>
        <Pill idx={1} name={"Schedule"} selected={selected} setSelected={setSelected} setTabName={setTabName}/>
        <Pill idx={2} name={"Locations"} selected={selected} setSelected={setSelected} setTabName={setTabName}/>
        <Pill idx={4} name={"Contact\u00A0Details"} selected={selected} setSelected={setSelected} setTabName={setTabName}/>
        <Pill idx={5} name={"Sign\u00A0up\u00A0for\u00A0things"} selected={selected} setSelected={setSelected} setTabName={setTabName}/>
        <Pill idx={6} name={"Stats"} selected={selected} setSelected={setSelected} setTabName={setTabName}/>
        <Pill idx={7} name={"Scavenge"} selected={selected} setSelected={setSelected} setTabName={setTabName}/>
        <Padding><Pill idx={8} name={"?"} selected={selected} setSelected={setSelected} setTabName={setTabName}/></Padding>

     </SwitchContainer>
     
    </>
  )
  
}

export default Header

const Padding = styled.div`
    padding-right: 10px;    
`

const Container = styled.div`

    background-color: rgb(186, 200, 218);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='735' height='88.2' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='rgb(195, 207, 222)' stroke-width='6.3' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Top = styled.div`
    height: 20px;
    background-color: rgb(30,31, 65);
`
const Left = styled.div`
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`

const TopText = styled.div`
    color: rgb(30,31, 65);
    font-weight: 600;
    font-size: 1.25em;
`

const BottomText = styled.div`
    color: rgb(30,31, 65);
    font-weight: 600;
    font-size: 2.5em;
    padding-bottom: 10px;
`

const SwitchContainer = styled.div`
    padding: 10px;
    background-color: rgb(30,31, 65);
    display: flex;
    overflow: scroll;
    position: sticky;
    top: 0;
    z-index: 100;
`
