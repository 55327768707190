import React from 'react'
import styled from 'styled-components'
import Table from './Table.js';
import scav from '../scav.png';

function Scavenge() {

    return (
      <Container>     
        <img src={scav} className="tocmap" alt="Logo" /> 
        <Table/>
      </Container>
    );
  }
  
  export default Scavenge;
  
  
  const Container = styled.div`

    background-color: white;
  
  `