import React, { useEffect } from 'react'
import styled from 'styled-components'

const Pill = ({name, selected, idx, setSelected, setTabName}) => {

  const handleClick = () => {
    setSelected(idx);
    setTabName(name);
  }

  return (
    <Outer>
    {selected === idx && <ContainerSelected onClick={handleClick}><PillTextSelected>{name}</PillTextSelected></ContainerSelected>}
    {!(selected === idx) &&  <Container onClick={handleClick}><PillText>{name}</PillText></Container>}
    </Outer>
  )
}

export default Pill

const Container = styled.div`
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    cursor: pointer;
`

const Outer = styled.div`
  border-radius: 100px;
  margin-left: 10px;
  border: 1px solid rgb(186, 200, 218);
  transition-duration: 0.2s;
  cursor: pointer;
  //:active {
    //  scale: 0.85;
      //background-color: rgb(30,31, 65);;
    //}
`

const PillText = styled.text`
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;    
    flex: 1;
    color: rgb(186, 200, 218);
    font-weight: 500;
`

const PillTextSelected = styled.div`
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    flex: 1;
    color: rgb(30,31, 65);
    font-weight: 500;
`

const ContainerSelected = styled.div`
    background-color: rgb(186, 200, 218);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex: 1;
    //:active {
      //scale: 0.85;
    //}
`