import { useEffect, useState } from 'react';
import './App.css';
import ContactDetails from './components/ContactDetails';
import Locations from './components/Locations';
import General from './components/General';
import Header from './components/Header';
import Home from './components/Home';
import Resources from './components/Resources';
import Schedule from './components/Schedule';
import styled from 'styled-components'
import logo from './logo.png'
import Fun from './components/Fun';
import { db } from './index.js'
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Where from './components/Where';
import Scavenge from './components/Scavenge';
function App() {

  const [sj, setSj] = useState();
  const [j, setJ] = useState();
  const [s, setS] = useState();
  const [g, setG] = useState();

  const [selected, setSelected] = useState(0);

  const getNumber = async () => {
    const docRef = doc(db, "buttons", "buttons");
    const docSnap = await getDoc(docRef);
    setSj(docSnap.get("sj"));
    setJ(docSnap.get("j"));
    setS(docSnap.get("s"));
    setG(docSnap.get("g"));
  }

  useEffect(() => {
      getNumber();
  }, [])

  return (
    <div className="App">
      {false &&<Container>
       <Inner>
        <img src={logo} className="logoLanding" alt="Logo" />
        <MainText>Welcome to the 2022 Bangkok Global Round!</MainText>
        <SmallText>This site will go live on September 1st</SmallText>
      </Inner> 
      </Container>}
      {true && <>
      <Header setSelected={setSelected} selected={selected}></Header>
      {selected === 0 && <General/>}
      {selected === 1 && <Schedule/>}
      {selected === 2 && <Locations/>}
      {selected === 4 && <ContactDetails/>}
      {selected === 5 && <Resources/>}
      {selected === 6 && <Home/>}
      {selected === 7 && <Scavenge/>}
      {selected === 8 && <Fun sj={sj} setSj={setSj} j={j} setJ={setJ} s={s} setS={setS} g={g} setG={setG} getNumber={getNumber}/>}
      </>}
    </div>
  );
}

export default App;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgb(30,31, 65);;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const MainText = styled.div`
  padding-top: 20px;
  font-weight: 600;
  font-size: 2em;
  color: white;
`

const Inner = styled.div`
  padding: 50px;
`

const SmallText = styled.div`
  padding-top: 20px;
  color: white;
`