import React from 'react'
import styled from 'styled-components'


const ContactDetails = () => {
  return (
    <Container>

        <ContactBox>
            <HeaderText>
                WSC Emergency Contacts
            </HeaderText>

            <SubtitleBox>
                <Subtitle>
                    Jason Hu
                </Subtitle>
                <Subtitle2>
                    +1 203 285 7010
                </Subtitle2>
            </SubtitleBox>
            <SubtitleBox>
                <Subtitle>
                    Olivia Watson
                </Subtitle>
                <Subtitle2 className='extraPadding'>
                    +1 203 285 7171
                </Subtitle2>
            </SubtitleBox>
        </ContactBox>

        <ContactBox>
            <HeaderText>
                WSC Shuttle Bus Queries
            </HeaderText>

            <SubtitleBox>
                <Subtitle>
                    Vishal Verma
                </Subtitle>
                <Subtitle2 className='extraPadding2'>
                    +1 818 961 4231
                </Subtitle2>
            </SubtitleBox>
        </ContactBox>

        <ContactBox>
            <HeaderText>
                USA Emergency Services
            </HeaderText>

            <SubtitleBox>
                <Subtitle>
                    Safety and Medical Emergencies
                </Subtitle>
                <Subtitle2 className='extraPadding2'>
                    911
                </Subtitle2>
            </SubtitleBox>
        </ContactBox>

        <ContactBox>
            <HeaderText>
                All other inquiries
            </HeaderText>

            <SubtitleBox>
                <Subtitle2 className='extraPadding2'>
                contact@scholarscup.org
                </Subtitle2>
            </SubtitleBox>
        </ContactBox>

    </Container>
  )
}

export default ContactDetails

const Container = styled.div`
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    padding-top: 25px;

`

const HeaderText = styled.div`
    color: white;
    font-size: 1.25em;
    padding: 10px;
    background-color: rgb(30,31, 65);
`

const Subtitle = styled.text`
    font-weight: 600;
    font-size: 1.25em;
    padding-bottom: 0px;
`

const Subtitle2 = styled.text`
    padding-top: 5px;
    font-size: 1.25em;
`

const ContactBox = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
    -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
    min-width: 300px;
    flex: 1;
    margin: 0px 25px 25px 25px;
`

const SubtitleBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    padding-bottom: 5px;
`