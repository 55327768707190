import React from 'react'
import styled from 'styled-components'
import { IconContext } from 'react-icons'
import { FaSchool, FaSmile, FaFlag} from 'react-icons/fa'
import ReactCountryFlag from "react-country-flag"

const Home = () => {
  return (
    <>
    <Container>
      <Scholars>
        <Header>
          <IconContext.Provider value={{ color: 'rgb(30,31, 65);', size: '5em' }}>
            <div>
              <FaSmile />
            </div>
          </IconContext.Provider>
          <span style={{fontWeight:800, fontSize: "4em"}}>1495</span><br/>
          <span style={{fontWeight:600, fontSize: "2em"}}>Scholars</span>
            
        </Header>
      </Scholars>

      <Scholars>
        <Header>
          <IconContext.Provider value={{ color: 'rgb(30,31, 65);', size: '5em' }}>
            <div>
              <FaFlag />
            </div>
          </IconContext.Provider>
          <span style={{fontWeight:800, fontSize: "4em"}}>41</span><br/>
          <span style={{fontWeight:600, fontSize: "2em"}}>Countries</span>
        </Header>
        <FlagContainer>
          <FlagBar><ReactCountryFlag countryCode="AL" /><CountryText>Albania</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="AU" /><CountryText>Australia</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="AZ" /><CountryText>Azerbaijan</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="BH" /><CountryText>Bahrain</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="BD" /><CountryText>Bangladesh</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="BW" /><CountryText>Botswana</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="BG" /><CountryText>Bulgaria</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="KH" /><CountryText>Cambodia</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="CA" /><CountryText>Canada</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="EG" /><CountryText>Egypt</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="FR" /><CountryText>France</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="GE" /><CountryText>Georgia</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="IN" /><CountryText>India</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="ID" /><CountryText>Indonesia</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="IL" /><CountryText>Israel</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="JP" /><CountryText>Japan</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="JO" /><CountryText>Jordan</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="KZ" /><CountryText>Kazakhstan</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="KE" /><CountryText>Kenya</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="KR" /><CountryText>Korea</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="KG" /><CountryText>Kyrgyzstan</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="MY" /><CountryText>Malaysia</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="MV" /><CountryText>Maldives</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="MN" /><CountryText>Mongolia</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="NP" /><CountryText>Nepal</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="NZ" /><CountryText>New Zealand</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="MK" /><CountryText>North Macedonia</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="PK" /><CountryText>Pakistan</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="RO" /><CountryText>Romania</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="SG" /><CountryText>Singapore</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="SI" /><CountryText>Slovenia</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="TW" /><CountryText>Taiwan</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="TZ" /><CountryText>Tanzania</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="TH" /><CountryText>Thailand</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="TR" /><CountryText>Türkiye</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="AE" /><CountryText>United Arab Emirates</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="GB" /><CountryText>United Kingdom</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="US" /><CountryText>United States of America</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="UZ" /><CountryText>Uzbekistan</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="VN" /><CountryText>Vietnam</CountryText></FlagBar>
          <FlagBar><ReactCountryFlag countryCode="ZM" /><CountryText>Zambia</CountryText></FlagBar>
        </FlagContainer>
      </Scholars>

      <Scholars>
        <Header>
          <IconContext.Provider value={{ color: 'rgb(30,31, 65);', size: '5em' }}>
            <div>
              <FaSchool />
            </div>
          </IconContext.Provider>
          <span style={{fontWeight:800, fontSize: "4em"}}>209</span><br/>
          <span style={{fontWeight:600, fontSize: "2em"}}>Schools</span>
        </Header>
      </Scholars>
    </Container>
    </>
  )
}

export default Home

const CountryText = styled.div`
  flex: 1;
`

const FlagContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const FlagBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #EFEFEF;
`

const Container = styled.div`
  padding: 30px 30px 0px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;

`

const Scholars = styled.div`
  width: 100%;
  border-radius: 20px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
`

const Header = styled.div`
  color: rgb(30,31, 65);;
  padding: 30px;
`

const ShowBubble = styled.div`
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  position: sticky;
  bottom: 10px;
  margin: 10px;
  left: 100vw;
  height: 60px;
  aspect-ratio: 1;
  z-index: 10;
  background-color: #f5e3ad;
  border-radius: 100px;
  transition-duration: 0.2s;

  :active {
    scale: 0.8;
  }

`