import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import MultiSwitch from 'react-multi-switch-toggle'

const Where = () => {

const [sched, setSched] = useState(0);

    const [jrState, setJrState] = useState("");
    const [srState, setSrState] = useState("");

    const onToggle = (selectedItem) => {
        setSched(selectedItem)
    }

    const phrases = [
        "chillin",
        "making new friends",
        "eating at NUGGETS NUGGETS NUGGETS",
        "getting lost around New Haven",
        "window shopping at the Apple store?",
        "looking for your nametag",
        "crying about the Scholar's Challenge",
        "craving Shake Shack",

    ]

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
      }

    const terribleFunctionJr = () => {

        let cur = Date.now()/1000;

        setJrState(phrases[getRandomInt(0, phrases.length)])
        
        if (cur >= 1668121200) {
            setJrState("at the opening ceremony");
        }
        if (cur >= 1668132000) {
            setJrState("at lunch");
        }
        if (cur >= 1668135600) {
            setJrState("assembling for the Scholar's Scavenge");
        }
        if (cur >= 1668137400) {
            setJrState("at the Scholar's Scavenge (say hi to Painted Warrior!)");
        }
        if (cur >= 1668149100) {
            setJrState("regrouping with your school to head to dinner");
        }
        if (cur >= 1668150000) {
            setJrState("at dinner");
        }
        if (cur >= 1668146400) {
            setJrState(phrases[getRandomInt(0, phrases.length)])
        }
        if (cur >= 1668200400) {
            setJrState("getting ready for your favorite day of ToC");
        }
        if (cur >= 1668202200) {
            setJrState("at the Scholar's Challenge venue");
        }
        if (cur >= 1668204000) {
            setJrState("doing the Scholar's Challenge");
        }
        if (cur >= 1668207600) {
            setJrState("not on your phone since you are doing the Scholar's Challenge");
        }
        if (cur >= 1668209400) {
            setJrState("still not on your phone since you are doing the Scholar's Challenge");
        }
        if (cur >= 1668211200) {
            setJrState("debating or doing the Collaborative Writing");
        }
        if (cur >= 1668236400) {
            setJrState("regrouping with your school");
        }
        if (cur >= 1668240000) {
            setJrState(phrases[getRandomInt(0, phrases.length)])
        }
        if (cur >= 1668326400) {
            setJrState("preparing for the Scholar's Ball");
        }
        if (cur >= 1668331800) {
            setJrState("at the Scholar's Ball");
        }
        if (cur >= 1668340800) {
            setJrState(phrases[getRandomInt(0, phrases.length)])
        }
        if (cur >= 1668375000) {
            setJrState("preparing to head to the Scholar's Bowl");
        }
        if (cur >= 1668376800) {
            setJrState("at the Scholar's Bowl");
        }
        if (cur >= 1668392100) {
            setJrState("heading to lunch");
        }
        if (cur >= 1668393000) {
            setJrState("at lunch");
        }
        if (cur >= 1668395700) {
            setJrState("heading to the debate showcase");
        }
        if (cur >= 1668396600) {
            setJrState("watching the Debate Showcase");
        }
        if (cur >= 1668403800) {
            setJrState("watching the Scholar's Show");
        }
        if (cur >= 1668409200) {
            setJrState("heading to dinner if you are in the Omni Wave");
        }
        if (cur >= 1668412800) {
            setJrState("heading to dinner if you are in the Yale Wave");
        }
        if (cur >= 1668416400) {
            setJrState(phrases[getRandomInt(0, phrases.length)])
        }
        if (cur >= 1668488400) {
            setJrState("preparing for the Cultural Fair");
        }
        if (cur >= 1668501000) {
            setJrState("heading to the Cutural Fair");
        }
        if (cur >= 1668510000) {
            setJrState(phrases[getRandomInt(0, phrases.length)])
        }
        if (cur >= 1668551400) {
            setJrState("heading to the Scholar's Show");
        }
        if (cur >= 1668553200) {
            setJrState("at the Scholar's Show");
        }
        if (cur >= 1668564000) {
            setJrState("heading to the farewell luncheon");
        }
        if (cur >= 1668564900) {
            setJrState("at the farewell luncheon");
        }
        if (cur >= 1668569400) {
            setJrState("heading to the closing ceremony");
        }
        if (cur >= 1668573900) {
            setJrState("at the closing ceremony");
        }
        if (cur >= 1668581100) {
            setJrState("collecting too many medals because we don't understand moderation");
        }
        if (cur >= 1668582000) {
            setJrState("at the closing ceremony");
        }
        if (cur >= 1668585600) {
            setJrState("heading home");
        }

        if (cur >= 1668668400) {
            setJrState("chillin. See you next year");
        }
    }

    const terribleFunctionSr = () => {

        let cur = Date.now()/1000;

        setSrState(phrases[getRandomInt(0, phrases.length)])
        if (cur >= 1668121200 + 86400) {
            setSrState("at the opening ceremony");
        }
        if (cur >= (1668132000 + 86400)) {
            setSrState("at lunch");
        }
        if (cur >= 1668135600 + 86400) {
            setSrState("assembling for the Scholar's Scavenge");
        }
        if (cur >= 1668137400 + 86400) {
            setSrState("at the Scholar's Scavenge (say hi to Painted Warrior!)");
        }
        if (cur >= 1668149100 + 86400) {
            setSrState("regrouping with your school to head to dinner");
        }
        if (cur >= 1668150000 + 86400) {
            setSrState("at dinner");
        }
        if (cur >= 1668146400 + 86400) {
            setSrState(phrases[getRandomInt(0, phrases.length)])
        }
        if (cur >= 1668200400 + 86400) {
            setSrState("getting ready for your favorite day of ToC");
        }
        if (cur >= 1668202200 + 86400) {
            setSrState("at the Scholar's Challenge venue");
        }
        if (cur >= 1668204000 + 86400) {
            setSrState("doing the Scholar's Challenge");
        }
        if (cur >= 1668207600 + 86400) {
            setSrState("not on your phone since you are doing the Scholar's Challenge");
        }
        if (cur >= 1668209400 + 86400) {
            setSrState("still not on your phone since you are doing the Scholar's Challenge");
        }
        if (cur >= 1668211200 + 86400) {
            setSrState("debating or doing the Collaborative Writing");
        }
        if (cur >= 1668236400 + 86400) {
            setSrState("regrouping with your school");
        }
        if (cur >= 1668240000 + 86400) {
            setSrState(phrases[getRandomInt(0, phrases.length)])
        }
        if (cur >= 1668326400 + 86400) {
            setSrState("preparing for the Scholar's Ball");
        }
        if (cur >= 1668331800 + 86400) {
            setSrState("at the Scholar's Ball");
        }
        if (cur >= 1668340800 + 86400) {
            setSrState(phrases[getRandomInt(0, phrases.length)])
        }
        if (cur >= 1668375000) {
            setSrState("preparing to head to the Scholar's Bowl");
        }
        if (cur >= 1668376800) {
            setSrState("at the Scholar's Bowl");
        }
        if (cur >= 1668392100) {
            setSrState("heading to lunch");
        }
        if (cur >= 1668393000) {
            setSrState("at lunch");
        }
        if (cur >= 1668395700) {
            setSrState("heading to the debate showcase");
        }
        if (cur >= 1668396600) {
            setSrState("watching the Debate Showcase");
        }
        if (cur >= 1668403800) {
            setSrState("watching the Scholar's Show");
        }
        if (cur >= 1668409200) {
            setSrState("heading to dinner if you are in the Omni Wave");
        }
        if (cur >= 1668412800) {
            setSrState("heading to dinner if you are in the Yale Wave");
        }
        if (cur >= 1668416400) {
            setSrState(phrases[getRandomInt(0, phrases.length)])
        }
        if (cur >= 1668488400) {
            setSrState("preparing for the Cultural Fair");
        }
        if (cur >= 1668501000) {
            setSrState("heading to the Cutural Fair");
        }
        if (cur >= 1668510000) {
            setSrState(phrases[getRandomInt(0, phrases.length)])
        }
        if (cur >= 1668551400) {
            setSrState("heading to the Scholar's Show");
        }
        if (cur >= 1668553200) {
            setSrState("at the Scholar's Show");
        }
        if (cur >= 1668564000) {
            setSrState("heading to the farewell luncheon");
        }
        if (cur >= 1668564900) {
            setSrState("at the farewell luncheon");
        }
        if (cur >= 1668569400) {
            setSrState("heading to the closing ceremony");
        }
        if (cur >= 1668573900) {
            setSrState("at the closing ceremony");
        }
        if (cur >= 1668581100) {
            setSrState("collecting too many medals because we don't understand moderation");
        }
        if (cur >= 1668582000) {
            setSrState("at the closing ceremony");
        }
        if (cur >= 1668585600) {
            setSrState("heading home");
        }

        if (cur >= 1668668400) {
            setSrState("chillin. See you next year");
        }
    }

    useEffect(() => {
        terribleFunctionJr();
        terribleFunctionSr();
    })




  return (
    <Container>
        <SwitchContainer>
            <MultiSwitch
                texts={[
                'Junior',
                'Senior',
                ]}
                selectedSwitch={0}
                bgColor={'#ffffff'}
                onToggleCallback={onToggle}
                fontColor={'rgb(30,31, 65)'}
                selectedFontColor={'#FFFFFF'}
                eachSwitchWidth={100}
                height={'25px'}
                fontSize={'16px'}
                selectedSwitchColor={"rgb(30,31, 65)"}
                borderWidth={"1"}
            >
            </MultiSwitch>
        </SwitchContainer>

        {sched === 0 && <InfoBox>
            You should be 
            <Bold>
                {jrState}
            </Bold>
        </InfoBox>}

        {sched === 1 && <InfoBox>
            You should be 
            <Bold>
                {srState}
            </Bold>
        </InfoBox>}

    </Container>
  )
}

export default Where

const Container = styled.div`
    padding: 0px 30px 30px 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60vh;
`

const SwitchContainer = styled.div`
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
`

const InfoBox = styled.div`
    width: 90%;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
    -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    padding: 20px;
`

const Bold = styled.div`
    font-weight: 600;
`