import React from 'react'
import styled from 'styled-components'

const Resources = () => {
  return (
    <Container>
        <ContactBox>
            <HeaderText>
                Scholar's Show Sign Up
            </HeaderText>
            <SubtitleBox>
                <Subtitle2>
                <a className="showLink" href="https://scholarscup.typeform.com/to/x0817sM4">Sign Up</a>
                </Subtitle2>
            </SubtitleBox>
        </ContactBox>

        <ContactBox>
            <HeaderText>
                Scholar's Fair Sign Up
            </HeaderText>
            <SubtitleBox>
                <Subtitle2>
                <a className="showLink" href="https://scholarscup.typeform.com/to/Xjg0pAn8">Sign Up</a>
                </Subtitle2>
            </SubtitleBox>
        </ContactBox>

        <ContactBox>
            <HeaderText>
                Flag March Sign Up
            </HeaderText>
            <SubtitleBox>
                <Subtitle2>
                <a className="showLink" href="https://scholarscup.typeform.com/to/HQfJPIQq">Sign Up</a>
                </Subtitle2>
            </SubtitleBox>
        </ContactBox>

        
        <LastUpdate>Last updated: 2022 November 10</LastUpdate>
    </Container>
  )
}

export default Resources

const Container = styled.div`
    padding: 0px 30px 30px 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const HeaderText = styled.div`
    color: white;
    font-size: 1.25em;
    padding: 10px;
    background-color: rgb(30,31, 65);
`

const Subtitle2 = styled.text`
    padding-top: 5px;
    padding-bottom: 15px;
    font-size: 1.25em;
    transition-duration: 0.2s;
    color: white;
    :active {
        scale: 0.85;
    }
`

const ContactBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border-radius: 20px;
    overflow: hidden;
     -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
    -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
    width: 100%;
`


const SubtitleBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    padding-bottom: 5px;
`

const LastUpdate = styled.div`
    color: lightgray;
    position: absolute;
    text-align: center;
    bottom: 0px;
    z-index: -1;
    background-color: white;
    width: 100%;
    padding-top: 200px;
    padding-bottom: 20px;
`


/***
 * <ContactBox className="pressableBox">
            <SubtitleBox>
                <a className="regularLink" href="https://www.dropbox.com/s/4fk5hardi0hnb00/Bangkok%20Welcome%20Booklet%20FINAL.pdf?dl=0">
                <Subtitle2>Global Round Welcome Booklet</Subtitle2>
                </a>
            </SubtitleBox>
        </ContactBox>
 */