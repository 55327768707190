import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { db } from '../index.js'
import { doc, getDoc, updateDoc } from "firebase/firestore";

const Fun = ({sj, setSj, j, setJ, s, setS, g, setG, getNumber}) => {

    const setNumber = async (type) => {
        const docRef = doc(db, "buttons", "buttons");
        getNumber();
        let value = 0;
        if (type === "j") {
            value = j + 1;
            setJ(value);
            await updateDoc(docRef, {j: value});
            getNumber();
        };

        if (type === "s") {
            value = s + 1;
            setS(value);
            await updateDoc(docRef, {s: value});
            getNumber();
        };
    }

  return (
    <Container>
        <Title>
            Button?
        </Title>
        {j && s && <ButtonContainer>
            <ButtonBox>
                <CountBox>{j}</CountBox>
                <Button onClick={() => setNumber("j")} className='redButton'>JUNIOR</Button>
            </ButtonBox>
            <ButtonBox>
                <CountBox>{s}</CountBox>
                <Button onClick={() => setNumber("s")} className='blueButton'>SENIOR</Button>
            </ButtonBox>            
        </ButtonContainer>}
        <Refresh onClick={getNumber}>Refresh</Refresh>
    </Container>
  )
}

export default Fun

const Container = styled.div`
    padding: 10px 30px 30px 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Title = styled.div`
    padding-top: 20px;
    color: rgb(30,31, 65);;
    font-weight: 600;
    font-size: 2em;
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    flex-wrap: wrap;
    cursor: pointer;
`

const Button = styled.div`
    aspect-ratio: 1;
    border-radius: 20px;
    transition-duration: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: white;
    font-size: 1.5em;
    width: 140px;
    cursor: pointer;

    :active {
        scale: 0.85;
        cursor: pointer;
    }
`

const ButtonBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    cursor: pointer;
`

const CountBox = styled.div`
    border: 1.5px solid rgb(30,31, 65);;
    border-radius: 100px;
    width: 140px;
    margin-bottom: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 1.25em;
    font-weight: 600;
    color: rgb(30,31, 65);;
    cursor: pointer;
`

const Refresh = styled.div`
    margin-top: 30px;
    background-color: lightgray;
    color: white;
    width: 70px;
    padding: 5px;
    border-radius: 100px;
    cursor: pointer;
    transition-duration: 0.2s;

    :active {
        cursor: pointer;
        scale: 0.85;
    }
`
